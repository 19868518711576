import { useState, useCallback, useEffect } from 'react';

import type { AuthenticationEmitterType } from '@/context/authentication/model/events';

interface Dependencies {
  emitter: AuthenticationEmitterType;
}

/**
 * @function useLoginForm
 */
const useLoginForm = (deps: Dependencies) => {
  const { emitter } = deps;
  const [open, setOpen] = useState(false);

  /**
   * @function _onOpen
   */
  const _onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  /**
   * @function _onClose
   */
  const _onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    emitter.on('@authentication/open_modal', _onOpen);
    emitter.on('@authentication/close_modal', _onClose);
    return () => {
      emitter.off('@authentication/open_modal', _onOpen);
      emitter.on('@authentication/close_modal', _onClose);
    };
  }, [_onClose, _onOpen, emitter]);

  return open;
};

export default useLoginForm;
