import { parse } from 'express-useragent';

import type { IncomingMessage } from 'http';
import type { AppInitialState } from '../model/types';

import { INITIAL_STATE } from '../model/constants';

/**
 * @function parseInitialState
 * @param req
 */
const parseInitialState = (req?: IncomingMessage): AppInitialState => {
  if (!req) {
    return INITIAL_STATE;
  }

  const agent = parse(req.headers['user-agent'] ?? '');
  const botSource = agent.isBot && typeof agent.isBot === 'string' ? agent.isBot : 'non-bot';
  return {
    ...INITIAL_STATE,
    botSource: botSource,
    isBot: Boolean(agent.isBot),
    isMobile: agent.isMobile,
    isTablet: agent.isTablet,
    userAgent: agent.source,
  };
};

export default parseInitialState;
