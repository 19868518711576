import type { AppInitialState } from '../model/types';
import { INITIAL_STATE } from '../model/constants';

/**
 * @function getInitialState
 * @param initial
 */
const getInitialState = (initial?: AppInitialState): AppInitialState => {
  if (initial) {
    return initial;
  }

  const userAgent = typeof window !== 'undefined' && 'navigator' in window ? navigator.userAgent : '';
  return {
    ...INITIAL_STATE,
    userAgent: userAgent,
  };
};

export default getInitialState;
