import { useRef } from 'react';
import mitt from 'mitt';

import type { AuthenticationEvents } from '../model/events';

/**
 * @function useEmitter
 */
const useEmitter = () => {
  const _emmiter = useRef(mitt<AuthenticationEvents>());
  return _emmiter.current;
};

export default useEmitter;
