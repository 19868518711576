import type { VFC } from 'react';
import Portal from '@/components/Portal';
import SkipServer from '@/components/SkipServer';
import NotificationContainer from './NotificationContainer';

/**
 * @function Notification
 */
const Notification: VFC<unknown> = () => {
  return (
    <SkipServer>
      <Portal id="notification-portal">
        <NotificationContainer />
      </Portal>
    </SkipServer>
  );
};

export default Notification;
