import type { VFC } from 'react';
import { createContext, useState } from 'react';

import type { AppContextType, AppContextProps, AppInitialState } from '@/context/app/model/types';

import getInitialState from '@/context/app/utils/getInitialState';

/**
 * @constant AppContext
 */
export const AppContext = createContext<AppContextType>(undefined);

/**
 * @function AppProvider
 * @param props
 */
const AppProvider: VFC<AppContextProps> = props => {
  const { children, initialState } = props;
  const [state] = useState<AppInitialState>(() => getInitialState(initialState));

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export default AppProvider;
