// @ts-nocheck
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith#Polyfill
/* eslint-disable */
if (!String.prototype.startsWith) {
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function (s, r) {
      var p = r > 0 ? r | 0 : 0;
      return this.substring(p, p + s.length) === s;
    },
  });
}

const noop = {};
export default noop;
