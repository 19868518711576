import type { VFC, ReactNode } from 'react';
import type { AppInitialState } from '@/context/app';

import { useMemo } from 'react';
import { CookiesProvider, Cookies } from 'react-cookie';

import { AppProvider } from '@/context/app';
import { DeviceProvider } from '@/context/device';
import { AuthenticationProvider } from '@/context/authentication';
import { FirebaseProvider } from '@/context/firebase';
import { NotificationProvider } from '@/context/notification';
import { ResponsiveProvider } from '@/context/responsive';

interface Props {
  children: ReactNode;
  cookies?: string;
  initialState?: AppInitialState;
}

/**
 * @function CoreProvider
 * @param props
 */
const CoreProvider: VFC<Props> = props => {
  const { children, cookies: _cookies = '', initialState } = props;
  const cookies = useMemo(() => {
    return _cookies ? new Cookies(_cookies) : new Cookies();
  }, [_cookies]);

  return (
    <CookiesProvider cookies={cookies}>
      <AppProvider initialState={initialState}>
        <DeviceProvider>
          <ResponsiveProvider>
            <AuthenticationProvider>
              <FirebaseProvider>
                <NotificationProvider>{children}</NotificationProvider>
              </FirebaseProvider>
            </AuthenticationProvider>
          </ResponsiveProvider>
        </DeviceProvider>
      </AppProvider>
    </CookiesProvider>
  );
};

export default CoreProvider;
