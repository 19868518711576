import { useContext } from 'react';

import type { AuthenticationContextType } from '@/context/authentication/model/types';
import { AuthenticationContext } from '../authentication';

/**
 * @function useAuth
 */
const useAuth = () => {
  const context = useContext<AuthenticationContextType>(AuthenticationContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthenticationProvider');
  }
  return context;
};

export default useAuth;
