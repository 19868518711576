import type { VFC } from 'react';
import type { FallbackProps } from 'react-error-boundary';

import Image from 'next/image';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { PLACEHOLDER_EMPTY_STATE } from '@/constants/image';
import { containerCx, imageCx, contentCx } from '../styles';

/**
 * @function BoundaryError
 * @param props
 */
const BoundaryError: VFC<FallbackProps> = props => {
  const { resetErrorBoundary } = props;

  return (
    <div className={containerCx}>
      <div className={contentCx}>
        <Stack spacing={3}>
          <div className={imageCx}>
            <Image src={PLACEHOLDER_EMPTY_STATE} alt="not found" priority width={480} height={360} unoptimized />
          </div>
          <Stack>
            <Typography variant="h2" component="h1" align="center">
              Opps, Sepertinya Ada Kendala
            </Typography>
            <Typography variant="body2" component="div" align="center">
              Harap tunggu sebentar, server kami sedang ada kendala. Coba lagi atau kembali nanti.
            </Typography>
          </Stack>
          <Button type="button" variant="contained" size="large" onClick={resetErrorBoundary}>
            <Typography variant="body1-bold" component="span" align="center">
              Coba Lagi
            </Typography>
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default BoundaryError;
