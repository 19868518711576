import type { VFC, ReactNode } from 'react';
import { Fragment } from 'react';
import { useRouter } from 'next/router';

import Head from 'next/head';

import getNextPathname from '@/utils/url/getNextPathname';
import addProtocol from '@/utils/url/addProtocol';

import { HOSTNAME } from '@/constants';

interface Props {
  children: ReactNode;
}

/**
 * @function SEOLayout
 */
const SEOLayout: VFC<Props> = props => {
  const { children } = props;

  const { asPath } = useRouter();
  const pathname = getNextPathname(asPath);
  const url = addProtocol(`${HOSTNAME}${pathname}`);

  return (
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=no, width=device-width"
        />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="BestHome" />

        <meta name="application-name" content="BestHome" />
        <meta name="msapplication-TileColor" content="#34AC64" />
        <meta name="msapplication-TileImage" content="/logo/256.png" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#34AC64" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="HandheldFriendly" content="true" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />

        <title key="title">BestHome</title>
        <meta key="meta-description" name="description" content="Temukan property impian anda" />

        <meta key="og:title" property="og:title" content="BestHome" />
        <meta key="og:description" property="og:description" content="Temukan property impian anda" />
        <meta key="og:url" property="og:url" content={url} />
        <meta key="og:site_name" property="og:site_name" content="besthome" />
        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:locale" property="og:locale" content="en_US" />
        <meta key="og:image" property="og:image" content="/logo/1024.png" />
        <meta key="og:image:type" property="og:image:type" content="image/png" />
        <meta key="og:image:width" property="og:image:width" content="1024" />
        <meta key="og:image:height" property="og:image:height" content="1024" />
        <meta key="og:image:alt" property="og:image:alt" content="BestHome" />

        <meta key="robots" name="robots" content="index,follow" />
      </Head>
      {children}
    </Fragment>
  );
};

export default SEOLayout;
