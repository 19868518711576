import { forwardRef, useCallback } from 'react';
import { useNotifUpdater } from '@/context/notification';

import Alert from '@mui/material/Alert';

import type { NotificationItemProps } from './types';
import { NotificationItemContainer } from './styles';

/**
 * @function NotificationItem
 */
const NotificationItem = forwardRef<HTMLDivElement, NotificationItemProps>((props, ref) => {
  const { id, text, variant, animation } = props;
  const { remove } = useNotifUpdater();

  /**
   * @function handleOnClose
   */
  const handleOnClose = useCallback(() => {
    remove(id);
  }, [id, remove]);

  return (
    <NotificationItemContainer style={{ ...animation }}>
      <Alert ref={ref} variant="filled" color={variant} icon={false} onClose={handleOnClose}>
        {text}
      </Alert>
    </NotificationItemContainer>
  );
});

export default NotificationItem;
