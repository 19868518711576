import '@polyfills/object-entries';
import '@polyfills/requestIdleCallback';
import '@polyfills/String.prototype.endsWith';
import '@polyfills/String.prototype.startsWith';
import '@polyfills/String.prototype.trimStart';
import '@polyfills/matchmedia-polyfill';
import '@polyfills/abort-controller';

import '@/styles/font/inter.css';
import 'react-spring-bottom-sheet/dist/style.css';
import globalStyle from '@/styles/global';

import type { NextComponentType } from 'next';
import type { AppContext } from 'next/app';
import type { AppPropsWithLayout, AppIProps } from '@/model/types';

import { useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

import NextApp from 'next/app';
import { ErrorBoundary } from 'react-error-boundary';
import { Global } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

import CoreProvider from '@/context';
import Layout from '@/layouts';
import SEOLayout from '@/layouts/SEOLayout';

import BoundaryError from '@/components/Error/Boundary';
import Notification from '@/components/Notification';
import LogoutDialog from '@/components/LogoutDialog';

import theme from '@/styles/material/theme';
import parseInitialState from '@/context/app/utils/parseInitialState';

import QUERY_CLIENT_CONFIG from '@/utils/react-query-utilities/config';

/**
 * @function App
 * @param props
 */
const App: NextComponentType<AppContext, AppIProps, AppPropsWithLayout> = props => {
  const { Component, pageProps = {} } = props;
  const [client] = useState(() => new QueryClient(QUERY_CLIENT_CONFIG));

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <ErrorBoundary FallbackComponent={BoundaryError}>
          <QueryClientProvider client={client}>
            <Hydrate state={pageProps.dehydratedState}>
              <CoreProvider cookies={props.cookies} initialState={props.__initialState}>
                <SEOLayout>
                  <Layout layout={Component.getLayout}>
                    <Component {...pageProps} />
                  </Layout>
                  <Global styles={globalStyle} />
                </SEOLayout>
                <Notification />
                <LogoutDialog />
              </CoreProvider>
            </Hydrate>
          </QueryClientProvider>
        </ErrorBoundary>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};

/**
 * @function getInitialProps
 * @param appContext
 */
App.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const appProps = await NextApp.getInitialProps(appContext);
  const initialState = parseInitialState(ctx.req);

  return {
    ...appProps,
    cookies: (ctx.req && ctx.req.headers && ctx.req.headers.cookie) ?? '',
    __initialState: initialState,
  };
};

export default App;
