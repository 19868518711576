import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';

import type { APIError } from '@/utils/react-query-utilities/error';
import type { AuthenticationEmitterType } from '@/context/authentication/model/events';
import type { BaseResponse } from '@/model/types';
import type { FMOptions } from '@/utils/react-query-utilities/fetcher-mutation';
import type { LogoutResponse } from '@/model/logout';

import fetcherMutation from '@/utils/react-query-utilities/fetcher-mutation';
import { ENDPOINTS } from '@/constants/endpoints';

interface Dependencies {
  emitter: AuthenticationEmitterType;
  onSuccess: (data: LogoutResponse) => void;
  onError: (error: APIError<BaseResponse>) => void;
}

/**
 * @function useLogout
 * @param deps
 */
const useLogout = (deps: Dependencies) => {
  const { emitter, onSuccess, onError } = deps;
  const { mutate } = useMutation<LogoutResponse, APIError<BaseResponse>, FMOptions>({
    mutationFn: fetcherMutation,
    onSuccess: onSuccess,
    onError: onError,
  });

  /**
   * @function _mutate
   */
  const _mutate = useCallback(() => {
    const context = {
      path: ENDPOINTS.POST_LOGOUT,
    };

    mutate({ context: context });
  }, [mutate]);

  useEffect(() => {
    emitter.on('@authentication/logout', _mutate);
    return () => {
      emitter.off('@authentication/logout', _mutate);
    };
  }, [emitter, _mutate]);
};

export default useLogout;
