import type { FC } from 'react';
import { createContext, useMemo } from 'react';

import type {
  AuthenticationContextType,
  AuthenticationFormContextType,
  AuthenticationProviderProps,
} from '@/context/authentication/model/types';

import useAuthentication from '@/repository/is-authentication/client';
import useEmitter from '@/context/authentication/hooks/use-emitter';
import useLoginForm from '@/context/authentication/usecase/use-login-form';
import useLogoutEvent from '@/context/authentication/usecase/use-logout-event';

import useLogout from '@/context/authentication/repository/logout';

/**
 * @constant AuthenticationContext
 */
export const AuthenticationContext = createContext<AuthenticationContextType>(undefined);

/**
 * @constant AuthenticationFormContext
 */
export const AuthenticationFormContext = createContext<AuthenticationFormContextType>(undefined);

/**
 * @function AuthenticationProvider
 * @param props
 */
const AuthenticationProvider: FC<AuthenticationProviderProps> = props => {
  const { children } = props;

  const events = useEmitter();
  const open = useLoginForm({
    emitter: events,
  });

  const { isAuth, isAgent, loading } = useAuthentication();
  const { onSuccess, onError } = useLogoutEvent({
    emitter: events,
  });

  useLogout({
    emitter: events,
    onSuccess: onSuccess,
    onError: onError,
  });

  const context = useMemo<AuthenticationContextType>(() => {
    return {
      events: events,
      isAuth: isAuth,
      isAgent: isAgent,
      loading: loading,
    };
  }, [events, isAgent, isAuth, loading]);
  const formContext = useMemo<AuthenticationFormContextType>(() => {
    return {
      events: events,
      open: open,
    };
  }, [events, open]);

  return (
    <AuthenticationContext.Provider value={context}>
      <AuthenticationFormContext.Provider value={formContext}>{children}</AuthenticationFormContext.Provider>
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
