import http from './http';

export type FMOptions<TVars = any> = {
  variables?: TVars;
  context: {
    path: string;
    method?: string;
  };
};

/**
 * @function fetcherMutation
 * @param options
 */
const fetcherMutation = async (options: FMOptions) => {
  const { variables, context } = options;
  const { path = '', method } = context;
  const response = await http({
    path: path,
    body: variables,
    method: method ?? 'POST',
  });

  return Promise.resolve(response);
};

export default fetcherMutation;
