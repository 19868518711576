/**
 * @see https://github.com/withspectrum/spectrum
 * @function addProtocol
 * @param {string} url
 * @returns {string}
 */
const addProtocol = (url: string): string => {
  if (/^https?:\/\//i.test(url)) {
    return url;
  }

  return `https://${url}`;
};

export default addProtocol;
