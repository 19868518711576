import type { AppInitialState } from './types';

/**
 * @constant INITIAL_STATE
 */
export const INITIAL_STATE: AppInitialState = {
  botSource: 'non-bot',
  isBot: false,
  isMobile: false,
  isTablet: false,
  userAgent: '',
};
