import type { VFC, ReactNode, ReactElement, FunctionComponent, Component, ComponentType } from 'react';
import { isValidElement, Fragment, useState, useEffect, useRef } from 'react';

interface SkipServerPropsWithCalledComponent {
  children: ReactNode;
  enabled?: boolean;
  placeholder?: ComponentType<any>;
}

interface SkipServerPropsWithComponent {
  children: ReactNode;
  enabled?: boolean;
  placeholder?: ReactElement<unknown, string | FunctionComponent | typeof Component> | null;
}

type SkipServerProps = SkipServerPropsWithCalledComponent | SkipServerPropsWithComponent;

/**
 * @function SkipServer
 * @param {Props} props
 */
const SkipServer: VFC<SkipServerProps> = props => {
  const { enabled = false, children, placeholder } = props;
  const [visible, setVisible] = useState(!enabled);
  const rafOne = useRef<number>(0);
  const rafTwo = useRef<number>(0);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    rafOne.current = window.requestAnimationFrame(() => {
      rafTwo.current = window.requestAnimationFrame(() => {
        setVisible(true);
      });
    });

    return () => {
      window.cancelAnimationFrame(rafOne.current);
      window.cancelAnimationFrame(rafTwo.current);
    };
  }, [enabled]);

  if (!visible) {
    if (isValidElement(placeholder)) {
      return placeholder;
    }
    if (typeof placeholder === 'function') {
      const Placeholder = placeholder;
      return <Placeholder />;
    }
    return null;
  }
  return <Fragment>{children}</Fragment>;
};

export default SkipServer;
