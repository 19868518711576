/**
 * @constant ENDPOINTS
 * @description endpoint collection in key value pair
 */
export const ENDPOINTS = {
  ADD_EDIT_LEADS: '/main/api/v1/customer',
  GET_AGENT_HOME: '/main/api/v1/agent/home',
  GET_AGENT_INFO: '/main/api/v1/agent/info',
  GET_AGENT_MENU: '/main/api/v1/agent/menu',
  GET_AGENT_STATISTIC: '/main/api/v1/agent/statistic',
  GET_AGENT_STATS: '/main/api/v1/agent/stats',
  GET_AREA: '/main/api/v1/area',
  GET_CASH_REWARD_DETAIL: '/main/api/v1/cash-reward/detail',
  GET_CASH_REWARD_SUMMARY: '/main/api/v1/cash-reward/summary',
  GET_CHANNEL_EVENT_LIST: '/main/api/v1/channel-event',
  GET_CHANNEL_LIST: '/main/api/v1/channel',
  GET_CITY: '/main/api/v1/city',
  GET_COMMISION_DETAIL: '/main/api/v1/commision/detail',
  GET_COMMISION_SUMMARY: '/main/api/v1/commision/summary',
  GET_CRM_ACTIVITY: '/main/api/v1/activity',
  GET_CRM_CUSTOMER_BY_ID: '/main/api/v1/customer',
  GET_CRM_CUSTOMER_HISTORY: '/main/api/v1/customer/history',
  GET_DEVELOPER: '/main/api/v1/developer',
  GET_DISTRICT: '/main/api/v1/district',
  GET_FILTER_LIST: '/main/api/v1/filter-list',
  GET_HOME: '/main/api/v1/customer/home',
  GET_IS_AUTHENTICATE: '/api/v1/standard-user/is-authenticate',
  GET_IS_EMAIL_EXIST: '/api/v1/standard-user/is-exist/email',
  GET_LANDING_PAGE_URL_LIST: '/api/v1/landing-page/url/list',
  GET_LANDING_PAGE: '/api/v1/landing-page',
  GET_LEADS_LIST: '/main/api/v1/customer',
  GET_LISTING: '/main/api/v1/listing',
  GET_MAP_REQUEST: '/api/v1/maps',
  GET_MASTER_LISTING: '/main/api/v1/master-listing',
  GET_MENU: '/main/api/v1/customer/menu',
  GET_NEWS_EVENT: '/main/api/v1/news-event',
  GET_NON_CASH_REWARD_DETAIL: '/main/api/v1/non-cash-reward/detail',
  GET_NON_CASH_REWARD_SUMMARY: '/main/api/v1/non-cash-reward/summary',
  GET_NOTIFICATION_COUNT: '/main/api/v1/notification/new/count',
  GET_NOTIFICATION: '/main/api/v1/notifications',
  GET_NUP_LIST: '/main/api/v1/nup/project',
  GET_NUP_PAYMENT: '/main/api/v1/nup/payment',
  GET_NUP_TRANSACTION_LIST: '/main/api/v1/nup/tr',
  GET_NUP_TRANSACTION: '/main/api/v1/nup/tr',
  GET_PRESERVE_ADVANTAGE: '/main/api/v1/preserve-advantage',
  GET_PRODUCT: '/main/api/v1/product',
  GET_PROFILE_LISTING: '/main/api/v1/profile-listing',
  GET_PROJECT: '/main/api/v1/project',
  GET_PROPERTY_TYPE: '/main/api/v1/property-type',
  GET_PROVICE: '/main/api/v1/province',
  GET_SALES: '/main/api/v1/sales/transaction',
  GET_SEARCH_RESULT: '/main/api/v1/search',
  GET_SUB_TYPE: '/main/api/v1/sub-type',
  GET_TAG: '/main/api/v1/tag',
  GET_USER_INFO: '/main/api/v1/standard-user/info',
  GET_LAUNCHING_DATA: '/main/api/v1/launching/unit',
  POST_ASK_UNIT: '/main/api/v1/customer/ask-unit',
  POST_CHANGE_PASSWORD_STANDARD_USER: 'main/api/v1/standard-user/change-password',
  POST_CRM_NOTE: '/main/api/v1/agent-action',
  POST_INSTALLMENT_SIMULATION: '/main/api/v1/kpr-calculator/instalment',
  POST_LOGIN: '/api/v1/standard-user/login',
  POST_LOGOUT: '/api/v1/standard-user/logout',
  POST_MEDIA: '/file',
  POST_NUP_PAYMENT: '/main/api/v1/nup/payment',
  POST_OR_PUT_LISTING: '/main/api/v1/listing',
  POST_PRICE_SIMULATION: '/main/api/v1/kpr-calculator/price',
  POST_READ_NOTIFICATION: '/main/api/v1/notification',
  POST_REGISTER_STANDARD_USER: '/main/api/v1/standard-user/register',
  PUT_UPDATE_STANDARD_USER: '/main/api/v1/standard-user',
  GET_COUNTER_LIST: '/main/api/v1/launching/nup-queue',
};
