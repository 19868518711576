import type { VFC, ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';

interface Props {
  children: ReactNode;
  layout?: (page: ReactElement) => ReactNode;
}

/**
 * @function Layout
 * @param props
 */
const Layout: VFC<Props> = props => {
  const { children, layout = child => child } = props;
  return <Fragment>{layout(<Fragment>{children}</Fragment>)}</Fragment>;
};

export default Layout;
