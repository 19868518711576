import { css } from '@emotion/react';

const global = css`
  html {
    box-sizing: border-box;
    font-size: 16px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    color: rgba(49, 53, 59, 0.96);
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.26);
  }

  ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.26);
  }

  ::-moz-placeholder {
    color: rgba(0, 0, 0, 0.26);
  }

  :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.26);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export default global;
