// @ts-nocheck
/**
 * Polyfill for String.prototype.trimStart()
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/trimStart
 */
/* eslint-disable */

if (typeof window !== 'undefined') {
  (function (w) {
    var String = w.String,
      Proto = String.prototype;

    (function (o, p) {
      if (p in o ? (o[p] ? false : true) : true) {
        var r = /^\s+/;
        o[p] =
          o.trimLeft ||
          function () {
            return this.replace(r, '');
          };
      }
    })(Proto, 'trimStart');
  })(window);
}

const noop = {};
export default noop;
