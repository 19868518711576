import type { QueryStatus } from 'react-query';
import type { IsAuthenticateResponse, IsAuthenticateNData } from '@/model/is-authenticate';

/**
 * @function normalize
 * @param status
 * @param data
 */
const normalize = (status: QueryStatus, data?: IsAuthenticateResponse): IsAuthenticateNData => {
  const state: IsAuthenticateNData = {
    isAuth: false,
    isAgent: false,
  };

  if (status !== 'success' || !data || !data.data) {
    return state;
  }

  const role = data.data.role ?? [];
  state.isAuth = data.data.is_authenticate ?? false;
  state.isAgent = [1, 2].some(v => role.includes(v));
  return state;
};

export default normalize;
