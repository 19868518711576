import { css } from '@emotion/css';

export const containerCx = css`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 48px 0px 0px;
  margin: 0 auto;

  @media (min-width: 576px) {
    padding: 128px 0px 0px;
    height: 100vh;
  }
`;

export const imageCx = css`
  display: flex;
  max-width: 240px;
  margin: 0 auto;
`;

export const contentCx = css`
  display: flex;
  flex-flow: column;
  padding: 0px 16px;
`;
