import { useState, useEffect, useCallback } from 'react';

import type { Unsubscribe } from 'firebase/messaging';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import localforage from 'localforage';

import firebase from '@/firebase';

import { FIREBASE_VAPID_KEY } from '@/constants';
import { FCM_TOKEN_KEY } from '../model/constants';

/**
 * @TODO need to send the token to backend for notification to work. If web doesn't need
 * notification, just remove the codebase later on to improve bundle size if needed.
 *
 * @function useCloudMessaging
 */
const useCloudMessaging = () => {
  const [token, setToken] = useState('');

  /**
   * @function _requestOrCheckPermission
   */
  const _requestOrCheckPermission = useCallback(async () => {
    try {
      if (Notification.permission === 'granted') {
        return true; // exit when user already given permission before
      }

      /**
       * Request only when user has not set the notification
       * configuration
       */
      if (Notification.permission !== 'denied') {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          return true; // exit when user just given permission
        }
      }

      return false; // exit when user does not give permission or ignore the request
    } catch (error) {
      console.log('[FCM] Error on request or check notification permission: ', error);
      return false;
    }
  }, []);

  /**
   * @description initialize firebase cloud messaging with our service worker scope.
   * @function _initialize
   * @param registration
   */
  const _initialize = useCallback(
    async (registration?: ServiceWorkerRegistration) => {
      if (!registration) {
        return;
      }

      try {
        console.log('[app] firebase cloud messaging initializing');
        const isAPISupported = await isSupported();
        if (!isAPISupported) {
          console.log('[app] cancel firebase cloud messaging initalization because browser is not supported');
          return;
        }

        const permitted = await _requestOrCheckPermission();
        if (!permitted) {
          console.log('[app] cancel firebase cloud messaging initalization because notification is disabled');
          return;
        }

        const storedToken = await localforage.getItem<string>(FCM_TOKEN_KEY);
        if (storedToken) {
          console.log('[app] firebase cloud messaging successfully initialized');
          setToken(storedToken);
          return;
        }

        const messaging = getMessaging(firebase);
        const freshtoken = await getToken(messaging, {
          vapidKey: FIREBASE_VAPID_KEY,
          serviceWorkerRegistration: registration,
        });

        if (!freshtoken) {
          console.log('[app] cancel firebase cloud messaging initalization because token is missing');
          return;
        }

        console.log('[app] firebase cloud messaging successfully initialized');
        setToken(freshtoken);

        localforage.setItem(FCM_TOKEN_KEY, freshtoken).catch(err => {
          console.error('[app] error when saving token: ', err);
        });
      } catch (error) {
        console.log('[app] firebase cloud messaging failed to initialized: ', error);
      }
    },
    [_requestOrCheckPermission],
  );

  /**
   * @description on client app (foreground message), the SDK does not handle displaying the incoming message.
   * Client app should define the behaviour when receiving "payload.notification" / "payload.data".
   * For now, we just display normal notification when receiving on "payload.notification" and ignore "payload.data".
   *
   * @function _subscribe
   */
  const _subscribe = useCallback((): Unsubscribe => {
    console.log('[app] firebase cloud messaging listening');

    const messaging = getMessaging(firebase);
    return onMessage(messaging, payload => {
      if (process.env.NODE_ENV === 'development') {
        console.log('[app] Received foreground message: ', payload);
      }

      if (payload.notification) {
        return new Notification(payload.notification.title || '', {
          body: payload.notification.body || '',
          icon: '/logo/128.png',
          badge: '/logo/128.png',
          image: '/logo/128.png',
        });
      }
      return;
    });
  }, []);

  /**
   * Add useEffect to get service worker registration on ready.
   */
  useEffect(() => {
    if (!('navigator' in window) || !('serviceWorker' in navigator)) {
      return;
    }

    navigator.serviceWorker.ready.then(_initialize);
  }, [_initialize]);

  /**
   * @TODO Need to send token to backend so they can notify us.
   * Initialize messaging after token is granted
   */
  useEffect(() => {
    if (!token) {
      return;
    }

    const unlisten = _subscribe();
    return () => {
      unlisten();
    };
  }, [_subscribe, token]);

  return;
};

export default useCloudMessaging;
