import { useContext } from 'react';

import type { NotificationUpdaterContextType } from '../model/types';
import { NotificationUpdaterContext } from '../notification';

/**
 * @function useNotifUpdater
 */
const useNotifUpdater = () => {
  const context = useContext<NotificationUpdaterContextType>(NotificationUpdaterContext);
  if (context === undefined) {
    throw new Error('useNotifUpdater must be used within a NotificationProvider');
  }
  return context;
};

export default useNotifUpdater;
