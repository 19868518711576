import type { VFC } from 'react';
import { createContext } from 'react';

import firebase from '@/firebase';
import useCloudMessaging from './usecase/use-cloud-messaging';

import type { FirebaseProviderProps, FirebaseContextType } from './model/types';

/**
 * @constant FirebaseContext
 */
export const FirebaseContext = createContext<FirebaseContextType>(undefined);

/**
 * @function FirebaseProvider
 * @param props
 */
const FirebaseProvider: VFC<FirebaseProviderProps> = props => {
  const { children } = props;

  useCloudMessaging();

  return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
};

export default FirebaseProvider;
