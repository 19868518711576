export interface RequestTimeoutInterface {
  id: number;
  cancel: () => void;
}

/**
 * Behaves the same as setTimeout, except uses requestAnimationFrame() for better performance.
 * @function requestTimeout
 * @param {Function} fn The callback function
 * @param {number} delay The delay in milliseconds
 */
const requestTimeout = (fn: () => any, delay: number): RequestTimeoutInterface => {
  const start = new Date().getTime();
  let handlerID: number;
  let cancelled = false;

  const cancel = () => {
    cancelled = true;
  };

  const loop = () => {
    if (cancelled) {
      return;
    }

    const current = new Date().getTime();
    const delta = current - start;

    if (delta >= delay) {
      fn();
      return;
    }

    handlerID = window.requestAnimationFrame(loop);
    return;
  };

  handlerID = window.requestAnimationFrame(loop);

  return {
    id: handlerID,
    cancel: cancel,
  };
};

export default requestTimeout;
