import type { QueryClientConfig } from 'react-query';

import fetcher from '@/utils/react-query-utilities/fetcher';

const QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      queryFn: fetcher,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 5 * 60 * 1000,
      staleTime: 3 * 60 * 1000,
    },
  },
};

export default QUERY_CLIENT_CONFIG;
