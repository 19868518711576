/**
 * @constant CONSTANTS
 */
const CONSTANTS = {
  API_HOST: String(process.env.NEXT_PUBLIC_API_HOST ?? ''),
  COOKIE_DID_KEY: 'DID',
  COOKIE_DOMAIN: String(process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? '.besthome.co.id'),
  FIREBASE_VAPID_KEY: String(process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY ?? ''),
  GOOGLE_MAPS_KEY: String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY ?? ''),
  GOOGLE_OAUTH_CLIENT_ID: String(process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID ?? ''),
  HOSTNAME: String(process.env.NEXT_PUBLIC_HOSTNAME ?? ''),
};

export const {
  API_HOST,
  COOKIE_DID_KEY,
  COOKIE_DOMAIN,
  FIREBASE_VAPID_KEY,
  GOOGLE_MAPS_KEY,
  GOOGLE_OAUTH_CLIENT_ID,
  HOSTNAME,
} = CONSTANTS;
