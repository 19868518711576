import { NotificationReducerState, NotificationReducerAction } from './model/types';

export const initialState = {
  notifs: [],
};

export const TYPES = {
  ADD_NOTIF: '@@NOTIF/ADD_NOTIF',
  REMOVE_NOTIF: '@@NOTIF/REMOVE_NOTIF',
};

export const reducer = (
  state: NotificationReducerState,
  action: NotificationReducerAction,
): NotificationReducerState => {
  switch (action.type) {
    case TYPES.ADD_NOTIF: {
      const copy = state.notifs.slice();
      copy.push({
        id: action.id,
        text: action.message || '',
        variant: action.variant || 'info',
        duration: action.duration || 3000,
      });

      return {
        ...state,
        notifs: copy,
      };
    }
    case TYPES.REMOVE_NOTIF: {
      const { id } = action;
      const isExist = id ? state.notifs.findIndex(entry => entry.id === id) !== -1 : false;
      if (!isExist) {
        return state;
      }

      const copy = state.notifs.slice();
      const filter = copy.filter(entry => entry.id !== id);

      return {
        ...state,
        notifs: filter,
      };
    }
    default:
      return state;
  }
};
