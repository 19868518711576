import type { FC, ReactNode } from 'react';
import { Fragment, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';

import { COOKIE_DOMAIN, COOKIE_DID_KEY } from '@/constants';

export interface DeviceContextProps {
  children?: ReactNode;
}

/**
 * @description This not actually a context, but its a top level element.
 * The main purpose of this component is to create unique device id with uuid and set it to cookie.
 * @function DeviceProvider
 * @param props
 */
const DeviceProvider: FC<DeviceContextProps> = props => {
  const { children } = props;
  const [cookies, setCookie] = useCookies([COOKIE_DID_KEY]);
  const deviceID: string | undefined = cookies[COOKIE_DID_KEY];

  useEffect(() => {
    if (deviceID) {
      return;
    }

    const value = uuidv4();
    const expires = new Date('2070-12-31');
    setCookie(COOKIE_DID_KEY, value, {
      path: '/',
      expires: expires,
      domain: COOKIE_DOMAIN,
    });
  }, [deviceID, setCookie]);

  return <Fragment>{children}</Fragment>;
};

export default DeviceProvider;
