import { useMemo } from 'react';
import { useQuery } from 'react-query';

import fetcher from '@/utils/react-query-utilities/fetcher';
import { ENDPOINTS } from '@/constants/endpoints';

import type { BaseResponse } from '@/model/types';
import type { IsAuthenticateResponse } from '@/model/is-authenticate';
import type { AuthenticationState } from '@/context/authentication/model/types';
import type { APIError } from '@/utils/react-query-utilities/error';

import normalize from './normalize';

/**
 * @function useAuthentication
 */
const useAuthentication = (): AuthenticationState => {
  const { status, data } = useQuery<any, APIError<BaseResponse>, IsAuthenticateResponse, string[]>({
    queryKey: [ENDPOINTS.GET_IS_AUTHENTICATE],
    queryFn: fetcher,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    notifyOnChangeProps: ['status', 'data'],
  });

  return useMemo(() => {
    const response = normalize(status, data);
    return {
      isAgent: response.isAgent,
      isAuth: response.isAuth,
      loading: status === 'loading',
    };
  }, [data, status]);
};

export default useAuthentication;
