// @ts-nocheck
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith#Polyfill
/* eslint-disable */
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (s, l) {
    if (l === undefined || l > this.length) {
      l = this.length;
    }
    return this.substring(l - s.length, l) === s;
  };
}

const noop = {};
export default noop;
