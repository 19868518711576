if (typeof window !== 'undefined') {
  (async () => {
    if (!('matchMedia' in window)) {
      await import(
        /* webpackChunkName: "matchmedia-polyfill", webpackPreload: true */
        'matchmedia-polyfill'
      );
    }

    if (!('matchMedia' in window && window.matchMedia('all').addListener)) {
      await import(
        /* webpackChunkName: "matchmedia-addListener-polyfill", webpackPreload: true */
        'matchmedia-polyfill/matchMedia.addListener'
      );
    }
  })();
}

const noop = {};
export default noop;
