import type { VFC, ReactNode } from 'react';
import { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: ReactNode;
  id?: string;
}

/**
 * @function Portal
 * @param props
 */
const Portal: VFC<Props> = props => {
  const { children, id = '__ROOT_PORTAL__' } = props;
  const [tick, setTick] = useState(0);
  const ref = useRef<HTMLDivElement | undefined>(undefined);

  useEffect(() => {
    const element = document.createElement('div');
    ref.current = element;

    element.setAttribute('id', id);
    element.setAttribute('role', 'presentation');
    document.body.appendChild(element);

    setTick(prev => prev + 1);

    return () => {
      document.body.removeChild(element);
    };
  }, [id]);

  if (tick === 0 || !ref.current) {
    return null;
  }
  return createPortal(children, ref.current);
};

export default Portal;
