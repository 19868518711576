import { useContext } from 'react';

import type { NotificationContextType } from '../model/types';
import { NotificationContext } from '../notification';

/**
 * @function useNotif
 */
const useNotif = () => {
  const context = useContext<NotificationContextType>(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotif must be used within a NotificationProvider');
  }
  return context;
};

export default useNotif;
