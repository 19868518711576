import { useMediaQuery } from 'react-responsive';

/**
 * @function useMobile
 */
const useMobile = (): boolean => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  return isMobile;
};

export default useMobile;
