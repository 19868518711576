import qs from 'query-string';

/**
 * @function getNextPathname
 * @param {string} url
 * @returns {string}
 */
const getNextPathname = (url?: string): string => {
  if (!url) {
    return '';
  }

  const parse = qs.parseUrl(url);
  return parse.url || '';
};

export default getNextPathname;
