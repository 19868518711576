import type { VFC } from 'react';
import { useCallback } from 'react';

import dynamic from 'next/dynamic';
import { ErrorBoundary } from 'react-error-boundary';

import { useAuth } from '@/context/authentication';

const LogoutDialog = dynamic<unknown>(() => import(/* webpackChunkName: "logout-dialog" */ './LogoutDialog'), {
  ssr: true,
});

/**
 * @function LazyLogoutDialog
 */
const LazyLogoutDialog: VFC<unknown> = () => {
  const { isAuth, loading } = useAuth();

  /**
   * @function handleOnMountError
   * @param error
   * @param info
   */
  const handleOnMountError = useCallback((error: Error, info: { componentStack: string }) => {
    console.groupCollapsed(`Error occured!`);
    console.error(`
        [ErrorBoundary] Source of Error: LazyLogoutDialog
        [ErrorBoundary] Error message: ${error.message}
        [ErrorBoundary] Error stack: ${error.stack}
        [ErrorBoundary] Component stack: ${info.componentStack}
      `);
    console.groupEnd();
  }, []);

  if (loading || !isAuth) {
    return null;
  }

  return (
    <ErrorBoundary fallback={null} onError={handleOnMountError}>
      <LogoutDialog />
    </ErrorBoundary>
  );
};

export default LazyLogoutDialog;
