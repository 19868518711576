import type { VFC } from 'react';
import { useRef } from 'react';
import { useTransition } from 'react-spring';

import type { NotificationType } from '@/context/notification';
import { useNotif } from '@/context/notification';

import NotificationItem from './NotificationItem';
import { NotificationOverlay, disabledCx } from './styles';

/**
 * @function NotificationContainer
 */
const NotificationContainer: VFC<unknown> = () => {
  const { notifs } = useNotif();

  const refs = useRef(new WeakMap<NotificationType, HTMLDivElement>());
  const transitions = useTransition(notifs, {
    keys: notif => notif.id,
    from: {
      height: 0,
    },
    enter: notif => async next => {
      const element = refs.current.get(notif);
      await next({
        height: element ? element.offsetHeight : 0,
      });
    },
    leave: () => async next => {
      await next({
        height: 0,
      });
    },
  });

  return (
    <NotificationOverlay css={[notifs.length === 0 ? disabledCx : undefined]}>
      {transitions((props, notif) => (
        <NotificationItem
          key={notif.id}
          ref={ref => ref && refs.current.set(notif, ref)}
          id={notif.id}
          text={notif.text}
          duration={notif.duration}
          variant={notif.variant}
          animation={props}
        />
      ))}
    </NotificationOverlay>
  );
};

export default NotificationContainer;
