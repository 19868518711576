import type { VFC } from 'react';
import { createContext, useReducer, useCallback, useMemo } from 'react';

import requestTimeout from '@/utils/dom/requestTimeout';

import type {
  NotificationContextType,
  NotificationUpdaterContextType,
  NotificationPayload,
  NotificationProviderProps,
} from './model/types';
import { initialState, reducer, TYPES } from './reducer';

/**
 * @constant NotificationContext
 */
export const NotificationContext = createContext<NotificationContextType>(undefined);

/**
 * @constant NotificationUpdaterContext
 */
export const NotificationUpdaterContext = createContext<NotificationUpdaterContextType>(undefined);

/**
 * @function NotificationProvider
 * @param props
 */
const NotificationProvider: VFC<NotificationProviderProps> = props => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * @function _onRemove
   * @param id
   */
  const _onRemove = useCallback((id: string) => {
    dispatch({
      type: TYPES.REMOVE_NOTIF,
      id: id,
    });
  }, []);

  /**
   * @function handleOnAdd
   * @param payload
   */
  const _onAdd = useCallback(
    (payload: NotificationPayload) => {
      const id = `${new Date().getTime()}`;
      const duration = payload.duration || 0;

      dispatch({
        type: TYPES.ADD_NOTIF,
        id: id,
        duration: duration,
        message: payload.message,
        variant: payload.variant || 'info',
      });

      if (duration !== 0) {
        requestTimeout(() => _onRemove(id), duration);
      }
    },
    [_onRemove],
  );

  const updater = useMemo(() => {
    return {
      push: _onAdd,
      remove: _onRemove,
    };
  }, [_onAdd, _onRemove]);

  return (
    <NotificationContext.Provider value={state}>
      <NotificationUpdaterContext.Provider value={updater}>{children}</NotificationUpdaterContext.Provider>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
