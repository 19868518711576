import type { BaseResponse } from '@/model/types';
import { APIError } from '@/utils/react-query-utilities/error';

/**
 * @function getErrorMessage
 * @param error
 */
const getErrorMessage = (error: unknown): string => {
  if (error instanceof APIError) {
    const { header } = (error as APIError<BaseResponse>).payload;
    const message = header.messages.reduce((_, curr) => curr, '');
    return message || '';
  }
  if (error instanceof Error) {
    return error.message ?? '';
  }
  return '';
};

export default getErrorMessage;
