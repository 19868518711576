import type { GetServerSidePropsContext } from 'next';

import logger from '@/utils/log';
import queryStringify from '@/utils/url/queryStringify';
import { API_HOST } from '@/constants';

import { APIError } from './error';

interface HttpOptions {
  path: string;
  baseURL?: string;
  params?: string;
  method?: string;
  headers?: Record<string, string>;
  body?: Record<string, unknown> | FormData;
}

type FetchArgumentType = Parameters<typeof fetch>;

type FetchInitType = NonNullable<FetchArgumentType['1']>;

type FetchBodyType = FetchInitType['body'];

type FetchHeaderType = NonNullable<FetchInitType['headers']>;

/**
 * @function timeout
 * @param promise the promise you want to execute
 * @param controller abort controller. This will trigger abort() if past ms
 * @param ms time to timeout in ms
 */
const timeout = <T>(promise: Promise<T>, controller: AbortController, ms: number) => {
  const timer = new Promise<never>(() => {
    setTimeout(() => controller.abort(), ms);
  });
  return Promise.race<T>([timer, promise]);
};

/**
 * @function http
 * @param options
 * @param context
 */
const http = async (options: HttpOptions, context?: GetServerSidePropsContext) => {
  const { path, baseURL, params, method = 'GET', headers = {}, body } = options;
  let _http_body: FetchBodyType = undefined;
  let _params: string | undefined = undefined;
  let _headers: FetchHeaderType = {
    ...headers,
    'x-device': 'web',
  };

  const contentType = 'Content-Type' in headers ? headers['Content-Type'] : 'application/json';

  if ('Content-Type' in headers && headers['Content-Type'] === 'multipart/form-data') delete headers['Content-Type'];
  else if (!('Content-Type' in headers)) headers['Content-Type'] = contentType;

  switch (method) {
    case 'DELETE':
    case 'POST':
    case 'PUT': {
      if (body && body instanceof FormData) {
        _http_body = body;
      } else if (body) {
        _http_body = JSON.stringify(body);
        _headers = { 'Content-Type': 'application/json' };
      } else {
        _http_body = '';
      }
      break;
    }
    case 'GET': {
      if (body && !(body instanceof FormData)) {
        _params = queryStringify(body);
      } else {
        _params = params;
      }

      _headers = { 'Content-Type': 'application/json' };
      break;
    }
    default:
      break;
  }

  const base = baseURL || API_HOST;
  const endpoint = base + path;
  const referer = (context && context.req.headers.referer) ?? '';
  const cookie = (context && context.req.headers.cookie) ?? '';
  const requestPath = endpoint + (_params ? `?${_params}` : '');

  if (referer) {
    _headers = { ..._headers, Referer: referer };
  }
  if (cookie) {
    _headers = { ..._headers, cookie: cookie };
  }

  /**
   * Log the request on server in development.
   * Change it later on if we want to log the request on production
   */
  if (process.env.NODE_ENV !== 'production') {
    if (typeof window === 'undefined') {
      logger.info(`${method} - ${requestPath}`);
    }
  }

  /**
   * Abort controller with polyfill for nodejs & old browser.
   * @see https://github.com/mo/abortcontroller-polyfill#readme
   * @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController#browser_compatibility
   */
  let controller: AbortController;
  if (typeof window === 'undefined') {
    const { AbortController: _AbortController } = require('abortcontroller-polyfill/dist/cjs-ponyfill');
    controller = new _AbortController();
  } else {
    controller = new AbortController();
  }

  const request = fetch(requestPath, {
    method: method,
    body: _http_body,
    signal: controller.signal,
    credentials: 'include',
    headers: _headers,
  }).catch((error: Error) => {
    if (error.name === 'AbortError') throw new Error('Request Aborted - Timeout Exceeded');
    throw error;
  });

  const response = await timeout(request, controller, 3500);
  const json = await response.json();
  if (!json && response.status >= 400 && response.status < 500) {
    throw new Error(`[${response.status}] ${response.statusText}`);
  } else if (response.status >= 400) {
    throw new APIError(`[${response.status}] ${response.statusText}`, json);
  }

  return json;
};

export default http;
